<form [formGroup]="quoteForm" (ngSubmit)="onSubmit()">
    <!-- {{quoteForm.controls.coverages.value | json }} -->
    <!-- {{manufacturer}}
    {{manufacturerControl.value}} -->
    <div class="container-fluid">
        <div class="row align-items-center pt-3 mb-5">
            <div class="col-auto pb-2 ps-2 pe-2">
                <div class="brand-modal me-2"></div>
            </div>
            <div class="col p-0">
                <h1 class="d-inline modal-h1">
                    Quoter
                </h1>
            </div>
            <div class="col-auto p-0">
                <button mat-icon-button [mat-dialog-close]="true">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="row" *ngIf="isPPSP">
            <div class="col-12">
                <p><b>Please call Protection Point for a custom quote if you do not see the desired manufacturer nor cover type or term you desire at (604) 952-5511 or (888) 952-5511.</b></p>
            </div>
        </div>
        <div class="row" *ngFor="let message of updateQuoteMessages">
            <div class="col-12">
                <span class="text-danger">{{message}}</span>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-6 col-12" *ngIf="canDealerSelect">
                <app-select-search
                  [options]="dealershipOptions"
                  formControlName="dealership"
                  [items]="dealers"
                  (onFilterChange)="getDealers($event)">
                </app-select-search>
            </div>
            <div class="col-md-3" *ngIf="canDealerSelect">
                <app-select-search
                  [options]="salespersonOptions"
                  formControlName="salesperson"
                  [items]="salespeople"
                  (onFilterChange)="getSalespeople($event)">
                </app-select-search>
            </div>
            <div class="col-12" [ngClass]="{'col-md-3': canDealerSelect, 'col-md-6': canDealerSelect === false}">
              <app-autocomplete-input
                [options]="customerNameOptions"
                formControlName="customer_name">
              </app-autocomplete-input>
            </div>
        </div>

        <div class="row">
            <p *ngIf="quoteForm.hasError('canQuote') || quoteForm.hasError('pastShortBase') || quoteForm.hasError('statusNotQuotable') || showIfReman || showIfRefurbished || failedVerifiSerialNumber"
                class="text-danger mb-4" [@fade]>We are unable to generate a quote online for this selection; please
                contact the program administrator for assistance.</p>
            <!-- <p *ngIf="pin17Control.hasError('modelExists') || quoteForm.hasError('modelExists')" class="text-danger mb-4" [@fade]>The PIN17 or Serial Number provided cannot be confirmed, please contact the program administrator for assistance.</p> -->
            <p *ngIf="
            quoteForm.hasError('mfrBase') || 
            warrantyExpirationControl.hasError('maxDate') || 
            warrantyExpirationControl.hasError('minDate')"
                class="text-danger mb-4" [@fade]>The warranty period does not match the warranty dates entered. Please
                contact the program administrator for assistance.</p>
            <p *ngIf="eteMultipileCoverages" class="text-danger mb-4" [@fade]>There appear to be are multiple coverages
                for this PIN in the system; please contact the program administrator for assistance.</p>
            <p *ngIf="canNotShort" class="text-danger mb-4" [@fade]>This machine appears to have a short base. Please
                contact the program administrator for assistance with this quote.</p>
            <p *ngIf="quoteForm.hasError('mfrBaseExpired')" class="text-danger mb-4">The base warranty appears to be
                expired. Please contact the program administrator for assistance</p>
            <p *ngIf="quoteForm.hasError('baseHours')" class="text-danger mb-4">The current hours exceed the standard
                warranty expiration hours. Please contact the program administrator for assistance</p>


            <p class="text-danger mb-4" *ngFor="let message of errorMessages">{{message}}</p>

            <div class="col-md-3">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{quoteTypeHeader}}</mat-label>
                    <mat-select formControlName="quote_type" #quoteTypeSelect required>
                        <mat-option *ngFor="let type of quoteTypes" [value]="type">
                            {{type.display_string}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="quoteTypeControl.hasError('required')">Quote Type is required.</mat-error>

                    <mat-error *ngIf="quoteTypeControl.errors?.serverError != null">
                        {{ quoteTypeControl?.errors?.serverError }}
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- <div class="col-4" *ngIf="showIfNew || showIfEte || showIfUsed || showIfReman || showIfRefurbished"> -->
            <div class="col-4" *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Serial Number</mat-label>
                    <input type="text" matInput formControlName="serial_number">
                    <mat-error *ngIf="serialNumberControl.invalid">Serial Number is required.</mat-error>
                </mat-form-field>
            </div>

            


            <div class="col-md-3" *ngIf="showIfNew || showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{saleTypeHeader}}</mat-label>
                    <mat-select formControlName="sale_type" #saleType id="sale_type_select"
                        (selectionChange)="saleTypeSelection($event)" required>
                        <mat-option *ngFor="let type of salesTypes" [value]="[type.id,type.sale_type].join(',')">
                            {{type.sale_type}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="saleTypeControl.invalid">Sale Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6" *ngIf="showIfNew  || showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{primaryApplicationHeader}}</mat-label>
                    <mat-select formControlName="use_type" required>
                        <mat-option *ngFor="let type of primaryApplications" [value]="type.use_type">
                            {{type.use_type}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="useTypeControl.invalid">Use Type is required.</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div  class="row">

            <div class="col-md-2" *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{ManufacturerHeader}}</mat-label>
                    <mat-select formControlName="manufacturer" >
                        <mat-option *ngIf="eteOptions?.manufacturer" [value]="eteOptions?.manufacturer">
                            {{eteOptions?.manufacturer}}
                        </mat-option>

                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipment Type</mat-label>
                    <mat-select formControlName="equipment_type">
                        <mat-option *ngIf="eteOptions?.equipmentType" [value]="eteOptions?.equipmentType">
                            {{eteOptions?.equipmentType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Model</mat-label>
                    <mat-select formControlName="model" #modelInput>
                        <mat-option *ngIf="eteOptions?.model" [value]="eteOptions?.model">
                            {{eteOptions?.model}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="col-md-4" *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Existing Coverage</mat-label>
                    <mat-select formControlName="existing_coverage">
                        <mat-option *ngIf="eteOptions?.existingCoverage" [value]="eteOptions?.existingCoverage">
                            {{eteOptions?.existingCoverage}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4"  *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Coverage Period</mat-label>
                    <mat-select formControlName="coverage_period">
                        <mat-option *ngIf="eteOptions?.coveragePeriod" [value]="eteOptions?.coveragePeriod">
                            {{eteOptions?.coveragePeriod}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- <div class="col-md-4"  *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Coverage Type</mat-label>
                    <mat-select formControlName="coverage_type">
                        <mat-option *ngIf="eteOptions?.coverageType" [value]="eteOptions?.coverageType">{{eteOptions?.coverageType}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="col-md-4"  *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Service Fee</mat-label>
                    <mat-select formControlName="service_fee_or_deductible">
                        <mat-option [value]="eteOptions?.serviceFee || 0">{{eteOptions?.serviceFee || 0}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{saleTypeHeader}}</mat-label>
                    <mat-select formControlName="sale_type" #saleType id="sale_type_select"
                        (selectionChange)="saleTypeSelection($event)" required>
                        <mat-option *ngFor="let type of salesTypes" [value]="[type.id,type.sale_type].join(',')">
                            {{type.sale_type}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="saleTypeControl.invalid">Sale Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6" *ngIf="showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{primaryApplicationHeader}}</mat-label>
                    <mat-select formControlName="use_type" required>
                        <mat-option *ngFor="let type of primaryApplications" [value]="type.use_type">
                            {{type.use_type}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="useTypeControl.invalid">Use Type is required.</mat-error>
                </mat-form-field>
            </div>

            
            
        </div>

        <div class="row">

            <div class="col-md-4" *ngIf="showIfNew  || showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{ManufacturerHeader}}</mat-label>
                    <mat-select formControlName="manufacturer" required>
                        <mat-option *ngFor="let mfr of (manufacturers)"
                            [value]="[mfr.manufacturer,mfr.rating_type].join(',')">
                            {{mfr.manufacturer}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="manufacturerControl.hasError('required')">Manufacturer is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="showIfNew  || showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipment Type</mat-label>
                    <mat-select formControlName="equipment_type" required>
                        <mat-option *ngFor="let type of equipmentTypes" [value]="type.id">
                            {{type.equipment_type}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="equipmentTypeControl.hasError('required')">Equipment Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4" *ngIf="showIfNew  || showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Model</mat-label>
                    <mat-select formControlName="model" #modelInput>
                        <mat-option *ngFor="let model of models" [value]="model.id">
                            {{model.model}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="modelControl.hasError('required')">Model is required.</mat-error>
                </mat-form-field>
            </div>



        </div>


        <div class="row">

            <div class="col-md-3" *ngIf="showIfNew  || showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Model Year</mat-label>
                    <mat-select formControlName="model_year" #modelYearInput>
                        <mat-option *ngFor="let year of modelYears" [value]="year">
                            {{year}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="modelYearControl.hasError('required')">Model Year is required.</mat-error>
                </mat-form-field>
            </div>



            <div class="col-md-3" *ngIf="showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipment Status</mat-label>
                    <mat-select formControlName="equipment_status">
                        <mat-option *ngFor="let status of equipmentStatuses" [value]="status.id">
                            {{status.status}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="equipmentStatusControl.invalid">Equipment Status is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="showIfNew && manufacturer == 'VOLVO'">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{shipDateHeader}}</mat-label>
                    <input matInput [matDatepicker]="shipDatePicker" formControlName="ship_date"
                        (click)="shipDatePicker.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="shipDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #shipDatePicker></mat-datepicker>
                    <mat-error *ngIf="shipDateControl.hasError('required')">Ship Date is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="showIfNew">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{deliveryDateHeader}}</mat-label>
                    <input matInput [min]="shipDate" [matDatepicker]="deliveryDatePicker"
                        formControlName="delivery_date" (click)="deliveryDatePicker.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="deliveryDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #deliveryDatePicker></mat-datepicker>
                    <mat-error *ngIf="deliveryDateControl.hasError('required')">{{deliveryDateHeader}} is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="showIfNew">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{warrantyExpirationHeader}}</mat-label>
                    <input matInput [min]="deliverDate" [matDatepicker]="warrantyExpDate"
                        formControlName="warranty_expiration" (click)="warrantyExpDate.open()" readonly />
                    <mat-datepicker-toggle matSuffix [for]="warrantyExpDate"></mat-datepicker-toggle>
                    <mat-datepicker #warrantyExpDate></mat-datepicker>
                    <mat-error *ngIf="warrantyDateControl.hasError('required')">Warranty Expiration is
                        required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="showIfNew">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{warrantyTermeHeader}}</mat-label>
                    <!-- <input matInput formControlName="warranty_term" /> -->
                    <mat-select formControlName="warranty_term" #warrantyTermInput>
                        <mat-option class="adaptive-content" *ngFor="let term of baseWarrantyTerms" [value]="term">
                            <p class="break-text">{{term}}</p>
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="warrantyTermControl.hasError('required')">Warranty Term is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-2" *ngIf="showIfNew  || showIfUsed  || showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{currentHoursHeader}}</mat-label>
                    <input matInput formControlName="current_hours" #input type="number" [min]="0" [max]="maxHours"
                        step="1" />
                    <mat-error *ngIf="currentHoursControl.hasError('required')">Current Hours is required.</mat-error>
                    <mat-error *ngIf="currentHoursControl.hasError('maxNumber')">Current Hours should not exceed
                        {{maxHours}}.</mat-error>
                    <mat-error *ngIf="currentHoursControl.hasError('min')">Current Hours should be positive.</mat-error>
                </mat-form-field>
            </div>

            <!-- hide this, but use this for two dropdowns: month & hour -->
            <!-- <div class="col-md-2" *ngIf="showIfNew || showIfUsed"> -->
            <!-- <div class="col-md-2" *ngIf="0">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{coverageTermHeader}}</mat-label>
                    <mat-select formControlName="term">
                        <mat-option *ngFor="let coverage of esppCoverages" [value]="coverage">
                            {{coverage.replace('9999','Unlimited')}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="termControl.hasError('required')">Term is required.</mat-error>
                </mat-form-field>
            </div> -->

            <div class="col-md-2" *ngIf="showIfNew || showIfUsed || showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Plan Term</mat-label>
                    <mat-select formControlName="planTerm_Months" (selectionChange)='afterPlanTermMonths()'>
                        <mat-option *ngFor="let planTermMonth of planTermMonths" [value]="planTermMonth">
                            {{planTermMonth}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="termControl.hasError('required')">Plan Term is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-2" *ngIf="showIfNew || showIfUsed || showIfEte">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Plan Hours</mat-label>
                    <mat-select formControlName="planTerm_Hours" (selectionChange)='afterPlanTermHours()'>
                        <mat-option *ngFor="let planTermHour of planTermHours" [value]="planTermHour">
                            {{planTermHour == 9999 ? 'Unlimited' : planTermHour}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="termControl.hasError('required')">Plan Hours is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-2" *ngIf="showIfNew  || showIfUsed">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>{{retailValueHeader}}</mat-label>
                    <input type="number" matInput formControlName="retail_value">
                </mat-form-field>
            </div>
            <div>
                <!-- <button type="submit" mat-flat-button color="primary" class="xl-button mt-2 mb-3" [disabled]="!quoteForm.valid">
                                Generate Quote
                            </button> -->
                <mat-dialog-actions align="end">
                    <button class="mt-3 mb-4" mat-flat-button [mat-dialog-close]="true">Cancel</button>
                    <button type="submit" class="mt-3 mb-4" mat-flat-button color="primary" [class.spinner]="loading"
                        [disabled]="disableSubmit" cdkFocusInitial>{{quote? 'Update Quote' : 'Generate Quote'}}
                    </button>
                    <!--
                    <button type="submit" class="mt-3 mb-4" mat-flat-button color="primary" [class.spinner]="loading"
                        [disabled]="!allEteDirty" cdkFocusInitial>{{ '[disabled]="!allEteDirty"' }}
                    </button>

                    <button type="submit" class="mt-3 mb-4" mat-flat-button color="primary" [class.spinner]="loading"
                        [disabled]="quoteForm.valid" cdkFocusInitial>{{'quoteForm.valid'}}
                    </button>

                    <button type="submit" class="mt-3 mb-4" mat-flat-button color="primary" [class.spinner]="loading"
                        [disabled]="!quoteForm.valid" cdkFocusInitial>{{ '!quoteForm.valid' }}
                    </button> -->
                </mat-dialog-actions>
            </div>

        </div>
    </div>
</form>