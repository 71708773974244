import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
type Coverage = { [key: string]: string }   

@Injectable()
export class CoverageTermService {

  constructor(private http: HttpClient) { }

  public getCoverageOptions(program: string, saleType: string, manufacturer: string, equipType: string, model: string,hours: number,serialNumber: string | null, ratingType: string, warranties: Array<{term: number, hours: number, coverage: string}> = []): Observable<Coverage[]> {
        let params = new HttpParams();
        params = params.append('program', program)
        params = params.append('quote_type', saleType)
        params = params.append('manufacturer', manufacturer)
        params = params.append('rating_type', ratingType)
        params = params.append('equip_type', equipType)
        params = params.append('model', model)
        params = params.append('hours', ''+hours)
        params = params.append('serial_number', serialNumber ?? '')
        params = params.append('warranties', warranties.map(w => `${w.term},${w.hours},${w.coverage}`).join('|'))

        return this.http.get<Coverage[]>('api/v1/espp/coverage_terms',{params});
    }
}
